var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import classNames from 'classnames';
import RcDrawer from 'rc-drawer';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import { NoFormStyle } from '../form/context';
import { getTransitionName } from '../_util/motion';
import { tuple } from '../_util/type';
const SizeTypes = tuple('default', 'large');
const defaultPushState = { distance: 180 };
function Drawer(_a) {
    var { width, height, size = 'default', closable = true, mask = true, push = defaultPushState, closeIcon = React.createElement(CloseOutlined, null), bodyStyle, drawerStyle, className, visible, children, zIndex, style, title, headerStyle, onClose, footer, footerStyle, prefixCls: customizePrefixCls, getContainer: customizeGetContainer, extra, afterVisibleChange } = _a, rest = __rest(_a, ["width", "height", "size", "closable", "mask", "push", "closeIcon", "bodyStyle", "drawerStyle", "className", "visible", "children", "zIndex", "style", "title", "headerStyle", "onClose", "footer", "footerStyle", "prefixCls", "getContainer", "extra", "afterVisibleChange"]);
    const { getPopupContainer, getPrefixCls, direction } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('drawer', customizePrefixCls);
    const getContainer = 
    // 有可能为 false，所以不能直接判断
    customizeGetContainer === undefined && getPopupContainer
        ? () => getPopupContainer(document.body)
        : customizeGetContainer;
    const closeIconNode = closable && (React.createElement("button", { type: "button", onClick: onClose, "aria-label": "Close", className: `${prefixCls}-close` }, closeIcon));
    function renderHeader() {
        if (!title && !closable) {
            return null;
        }
        return (React.createElement("div", { className: classNames(`${prefixCls}-header`, {
                [`${prefixCls}-header-close-only`]: closable && !title && !extra,
            }), style: headerStyle },
            React.createElement("div", { className: `${prefixCls}-header-title` },
                closeIconNode,
                title && React.createElement("div", { className: `${prefixCls}-title` }, title)),
            extra && React.createElement("div", { className: `${prefixCls}-extra` }, extra)));
    }
    function renderFooter() {
        if (!footer) {
            return null;
        }
        const footerClassName = `${prefixCls}-footer`;
        return (React.createElement("div", { className: footerClassName, style: footerStyle }, footer));
    }
    const drawerClassName = classNames({
        'no-mask': !mask,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    // ============================ Size ============================
    const mergedWidth = React.useMemo(() => width !== null && width !== void 0 ? width : (size === 'large' ? 736 : 378), [width, size]);
    const mergedHeight = React.useMemo(() => height !== null && height !== void 0 ? height : (size === 'large' ? 736 : 378), [height, size]);
    // =========================== Motion ===========================
    const maskMotion = {
        motionName: getTransitionName(prefixCls, 'mask-motion'),
        motionAppear: true,
        motionEnter: true,
        motionLeave: true,
    };
    const panelMotion = motionPlacement => ({
        motionName: getTransitionName(prefixCls, `panel-motion-${motionPlacement}`),
        motionAppear: true,
        motionEnter: true,
        motionLeave: true,
    });
    // =========================== Render ===========================
    return (React.createElement(NoFormStyle, { status: true, override: true },
        React.createElement(RcDrawer, Object.assign({ prefixCls: prefixCls, onClose: onClose }, rest, { open: visible, mask: mask, push: push, width: mergedWidth, height: mergedHeight, rootClassName: drawerClassName, getContainer: getContainer, afterOpenChange: open => {
                afterVisibleChange === null || afterVisibleChange === void 0 ? void 0 : afterVisibleChange(open);
            }, maskMotion: maskMotion, motion: panelMotion, rootStyle: style }),
            React.createElement("div", { className: `${prefixCls}-wrapper-body`, style: Object.assign({}, drawerStyle) },
                renderHeader(),
                React.createElement("div", { className: `${prefixCls}-body`, style: bodyStyle }, children),
                renderFooter()))));
}
if (process.env.NODE_ENV !== 'production') {
    Drawer.displayName = 'Drawer';
}
export default Drawer;
